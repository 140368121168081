import lozad from 'lozad'

window.lozad = lozad;

window.observer = window.lozad('img.lozad', {
    threshold: 0.1,
    rootMargin: '75% 75% 75% 75%',
    enableAutoReload: true,
    passive: true,
    load: function (el) {
        el.closest('picture').querySelectorAll('[data-srcset]').forEach((child) => {
            child.removeAttribute('loading')
            child.setAttribute('srcset', child.getAttribute('data-srcset'))
            child.removeAttribute('data-srcset')
        })
    },
    loaded: function (el) {
        setTimeout(() => {

            const lazyLoadWrap = el.closest('.lazy-load-wrap') || el;
            const placeholder = lazyLoadWrap ? lazyLoadWrap.querySelector('.lazy-load-placeholder') : null

            if (lazyLoadWrap) {
                if (lazyLoadWrap.getAttribute('data-lazy-remove')) lazyLoadWrap.classList.remove(...lazyLoadWrap.getAttribute('data-lazy-remove').split(' '))
                if (lazyLoadWrap.getAttribute('data-lazy-add')) lazyLoadWrap.classList.add(...lazyLoadWrap.getAttribute('data-lazy-add').split(' '))
            }

            if (placeholder) {
                if (placeholder.getAttribute('data-lazy-remove')) placeholder.classList.remove(...placeholder.getAttribute('data-lazy-remove').split(' '))

                if (placeholder.getAttribute('data-lazy-add')) {
                    placeholder.classList.add(...placeholder.getAttribute('data-lazy-add').split(' '))
                } else {
                    if (placeholder.getAttribute('class').indexOf('transition-') === -1) placeholder.classList.add('tw-transition-all')
                    if (placeholder.getAttribute('class').indexOf('duration-') === -1) placeholder.classList.add('tw-duration-500')
                    if (placeholder.getAttribute('class').indexOf('opacity-') === -1) placeholder.classList.add('tw-opacity-0')
                    placeholder.classList.add('tw-invisible')
                }
            }
        }, 100)
    }
});


// window.htmlObserver = window.lozad('lazy[data-lazy-id]', {
//     threshold: 0.1,
//     rootMargin: '125% 75% 125% 75%',
//     enableAutoReload: true,
//     passive: true,
//     load: function (el) {
//         if(el.innerHTML === '') {
//             let url = window.location.href;
//             let parts = url.split('/');
//             let lastSegment = parts.pop() || parts.pop();
//             let base = parts.join('/');
//             let html = lastSegment + '.' + el.getAttribute('data-lazy-id');
//
//             fetch(base + '/' + html)
//                 .then(response => response.text())
//                 .then(data => {
//                     //replace the lazy element with the fetched html
//                     el.innerHTML = data;
//                 });
//         }
//     },
//     loaded: function(el) {
//         // initialize all js components
//         setTimeout(() => {
//             window.observer.observe();
//             // trigger "AdditionalContentLoaded"
//             let event = new Event('AdditionalContentLoaded');
//             document.dispatchEvent(event);
//         }, 100)
//     }
// });

window.observer.observe();
// window.htmlObserver.observe();