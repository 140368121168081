import Swiper, { Pagination } from "swiper";
import 'swiper/css'
import 'swiper/css/pagination'

export default class ProductSlider
{
    constructor(rootNode, props) {
        if(this.slider = rootNode.querySelector('.swiper')) {
            this.slideCount = rootNode.querySelectorAll('.swiper-slide').length

            this.prev = rootNode.querySelector('.swiper-button-prev')
            this.next = rootNode.querySelector('.swiper-button-next')
            this.pagination = rootNode.querySelector('.swiper-pagination')
            
            let swiper = new Swiper(this.slider, {
                modules: [Pagination],
                slidesPerView: 1,
                spaceBetween: 15,
                loop: this.slideCount > 3,
                uniqueNavElements: false,
                pagination: {
                    el: this.pagination,
                    clickable: true,
                },
                breakpoints: {
                    480: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1280: {
                        slidesPerView: 3,
                        pagination: false,
                    }
                }
            })

            // Add event listeners to the next and previous buttons
            if(this.next) {
                this.next.addEventListener('click', () => {
                    swiper.slideNext();
                    window.observer.observe();
                });

                this.prev.addEventListener('click', () => {
                    swiper.slidePrev();
                    window.observer.observe();
                });
            }
        }
    }
}
