const win = window;

export default class Navigation {
    mainMenu = null;

    constructor(rootNode, props) {
        this.root = rootNode;
        this.mainMenu = rootNode.querySelector('.main-menu');

        window.addEventListener('scroll', this.scrollHandler.bind(this),
            {passive: true});
    }

    scrollHandler() {
        if (win.scrollY > 50) {
            this.root.classList.add('sticky');
        } else {
            this.root.classList.remove('sticky');
        }
    }
}
