export default class YoutubeVideo {
    constructor(rootNode, props) {
        this.root = rootNode
        this.iframe = rootNode.querySelector('iframe')

        // Passive scroll listener, if map is in viewport, trigger loadScript
        window.addEventListener('scroll', () => {
            if (this.isInViewport(this.root) && this.iframe && this.iframe.getAttribute('data-src')) {
                // replace data-src with src
                this.iframe.setAttribute('src', this.iframe.getAttribute('data-src'))
                this.iframe.removeAttribute('data-src')
            }
        }, {passive: true});
    }

    isInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom - 200 <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}