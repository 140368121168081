export default class ScrollSpy {
    constructor(rootNode) {
        this.root = rootNode
        this.links = rootNode.querySelectorAll('li a')

        this.scrollSpy()
    }

    scrollSpy() {
        document.addEventListener(
            'scroll',
            (event) => {
                const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;

                for (let link in this.links) {
                    if (this.links.hasOwnProperty(link)
                        && this.links[link].getAttribute('href')
                    ) {
                        const id = this.links[link].getAttribute('href').replace('#', '')
                        const section = document.getElementById(id)

                        if (section && section.offsetTop <= scrollPos) {
                            this.root.querySelectorAll('.active').forEach((el) => {
                                el.classList.remove('active')
                            })

                            this.links[link].parentNode.classList.add('active');
                        }
                    }
                }
            },
            {passive: true}
        )
    }
}
